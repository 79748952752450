//*----------  CHARTS  ----------*/
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxExport,
  DxTooltip
} from 'devextreme-vue/chart';

import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex';

var currentDate = new Date().toISOString().substr(0, 10);
export default {
  name: "Ticket",
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip
  },
  data: () => ({
  }),
  computed: {
        ...mapGetters("dashboard", {
          dataSource: "getDataRegistrosSucursal"
        }),
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.seriesName}: ${pointInfo.valueText}`
      };
    }
  },
  created() {},
  mounted() {

  }
}
